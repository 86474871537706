.loadinWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header{
    *{
        display: flex;
    }
    &__main{
        flex-direction: row;
        margin-top: 44px;
        justify-content: space-between;
        color: #E9E2D0;
        align-items: center;
        position: relative;

        &__logo{
            align-items: center;

            &_img{
                width: 64px;
                height: 64px;
            }

            &_text{
                font-size: 32px;
                font-weight: 400;
            }
        }

        &__title{
            font-size: 18px;
            font-weight: 400;
            text-transform: capitalize;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            &_surname{
                margin-left: 8px;
            }
        }

        &__exit{
            align-items: center;

            &_text{
                font-size: 18px;
                font-weight: 400;
                margin-right: 12px;
                text-decoration: underline;
            }

            &_img{
                width: 40px;
                height: 32px;
            }
        }

        &__exit:hover{
            cursor: pointer;
        }
    }
}

.wpap{
   flex-direction: column;
    display: flex;
    height: 100%;
}

@media only screen and (max-width: 500px) {
    .header{
        &__main{
            flex-direction: row;
            margin-top: 32px;
            justify-content: space-between;

            &__logo{
                display: none;
            }

            &__title{
                position: relative;
                left: 0%;
                transform: translateX(0%);
            }

            &__exit{
    
                &_text{
                    font-size: 16px;
                    margin-right: 10px;
                }
    
                &_img{
                    width: 28px;
                    height: 24px;
                }
            }
        }
    }
}