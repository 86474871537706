.forgot{

    *{
        display: flex;
    }

    &__wrapper{
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        padding: 32px 0px;

        &__header{
            flex-direction: column;
            width: 550px;
            align-items: center;

            &_img{
                width: 475px;
                height: 140px;
            }

            &__text{
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-top: 24px;

                &_title{
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 46px;
                    text-transform: uppercase;
                    color: #E9E2D0;
                }

                &_link{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 23px;
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    color: #E9E2D0;
                    transition: .5s all;
                }

                &_link:hover{
                    color: #9D9C9C;
                }
            }
        }

        &__main{
            flex-direction: column;
            padding: 20px 0px;
            width: 550px;
            align-items: center;
            justify-content: center;

            &_input{
                height: 60px;
                border: 1px solid #C99F62;
                border-radius: 15px;
                background-color: #241512;
                padding: 0px 24px;
                font-weight: 400;
                font-size: 22px;
                line-height: 31px;
                color: #D1CFCF;
                width: 550px;
            }

            &_singIn{
                height: 80px;
                width: 350px;
                background-color: #C99F62;
                border-radius: 0px 20px 0px 20px;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: #241512;
                font-weight: 600;
                font-size: 22px;
                line-height: 31px;
                cursor: pointer;
                transition: .5s all;
                margin-top: 44px;
            }

            &_singIn:hover{
                background-color: #D4B382;
            }

            &_singIn:active{
                background-color: #A17536;
            }

            &_singIn:disabled{
                cursor: default;
                background-color: #A6A6A6;

            }
        }

        &__footer{

            align-items: center;
            flex-direction: column;
            padding-bottom: 40px;

            &_text{
                color: #E9E2D0;
                font-size: 18px;
                margin-top: 64px;
            }

            &__wrapper{
                margin-top: 24px;

                &_img{
                    margin-left: 12px;
                    margin-right: 12px;
                }

                &_img:hover{
                    cursor: pointer;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .forgot{
        &__wrapper{
            height: calc(100vh - 80px);
            padding: 32px 0px 40px 0px;

            &__header{
                width: 100%;
    
                &_img{
                    width: 238px;
                    height: 72px;
                }
    
                &__text{
                    margin-top: 32px;
    
                    &_title{
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 31px;
                    }
    
                    &_link{
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 23px;
                        color: #9D9C9C;
                    }
    
                    &_link:hover{
                        color: #9D9C9C;
                    }
                }
            }

            &__main{
                width: 100%;
    
                &_input{
                    height: 55px;
                    width: 100%;
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 23px;
                }
    
                &_singIn{
                    height: 55px;
                    width: 100%;
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 23px;
                }
    
                &_singIn:hover{
                    background-color: #C99F62;
                }
    
                &_singIn:active{
                    background-color: #C99F62;
                }
    
                &_singIn:disabled{
                    background-color: #A6A6A6;
    
                }
            }
        
            &__footer{

                padding-bottom: 0px;
    
                &_text{
                    color: #E9E2D0;
                    font-size: 18px;
                    margin-top: 0px;
                }
    
                &__wrapper{
                    margin-top: 24px;
    
                    &_img{
                        margin-left: 24px;
                        margin-right: 24px;
                    }
    
                    &_img:hover{
                        cursor: pointer;
                    }
                }
            }
        }
    }
}