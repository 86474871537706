.main{
    flex-direction: column;
}
.full{
    flex-direction: row;
    margin-top: 24px;
    align-items: center;
    justify-content: space-between;

    &__mainInfo{
        height: 60px;
        width: 60%;
        border: 1px solid #C99F62;
        border-radius: 20px;
        justify-content: space-between;
        align-items: center;
        padding: 0px 24px;

        &__date{
            
            &_date{

            }

            &_day{
                margin-left: 24px;
            }

            &_time{
                margin-left: 24px;
            }
        }
    }

    &_paid{
        
        &_true{
            background-color: #13AD3E;
            width: 15%;
            height: 52px;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            cursor: pointer;
        }

        &_false{
            background-color: #C99F62;
            width: 15%;
            height: 52px;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            cursor: pointer;
        }
    }

    &_cancel{
        width: 15%;
        height: 52px;
        background-color: #AA1F00;
        color: white;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        cursor: pointer;
    }

    &__approveBlock{
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;

        &_button{
            width: 160px;
            height: 40px;
            background-color: #C99F62;
            border-radius: 20px;
            align-items: center;
            justify-content: center;
            margin-left: 24px;
            cursor: pointer;

            &_error{
                background-color: #AA1F00;
                color: white;
                width: 160px;
                height: 40px;
                border-radius: 20px;
                align-items: center;
                justify-content: center;
                margin-left: 24px;
                cursor: pointer;
            }

        }

        &_close{
            cursor: pointer;
        }

        &__main{
            align-items: center;
        }
    }
}