.lesson{
    font-size: 16px;
    margin-top: 16px;

    &_name{
        margin-left: 4px;
        text-transform: capitalize;
    }

    &_surname{
        margin-left: 4px;
        text-transform: capitalize;
    }
}