.loadinWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab{
    &__wrapper{
        margin-top: 120px;

        &_lessons, &_student{
            color: rgba(201, 159, 98, 0.5);
            font-weight: 400;
            font-size: 26px;
            line-height: 33px;
            letter-spacing: 0.03em;
            margin-left: 80px;
            cursor: pointer;
            transition: .5s all;

            &_active{
                color: #C99F62;
            }
        }
        &_lessons{
            margin-left: 0px;
        }

        &_lessons:hover, &_student:hover{
            color: #C99F62;
        }
    }
}