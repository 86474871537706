.signUp{
    height: 100vh;
    min-height: 800px;

    *{
        display: flex;
    }
    flex-direction: column;

    &__wrapper{
        align-items: center;
        flex-direction: column;
        height: 100vh;
        min-height: 800px;
        padding: 32px 0px;
        justify-content: space-between;

        &__header{

            flex-direction: column;
            width: 550px;
            align-items: center;

            &_img{
                width: 475px;
                height: 140px;
            }

            &__text{
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-top: 24px;

                &_title{
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 46px;
                    text-transform: uppercase;
                    color: #E9E2D0;
                }

                &_link{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 23px;
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    color: #E9E2D0;
                    transition: .5s all;
                }

                &_link:hover{
                    color: #9D9C9C;
                }
            }
        }
        &__main{
            flex-direction: column;
            width: 550px;

            &_input, &_inputName{
                height: 60px;
                border: 1px solid #C99F62;
                border-radius: 15px;
                background-color: #241512;
                padding: 0px 24px;
                font-weight: 400;
                font-size: 22px;
                line-height: 31px;
                color: #D1CFCF;
                margin-top: 24px;
            }

            &_input:first-child{
                margin-top: 0px;
            }

            &__wrapperInput{
                justify-content: space-between;
                margin-top: 24px;
            }

            &_inputName{
                width: 45%;
                margin-top: 0px;
            }

            &__errorState{
                margin-top: 16px;
                height: 20px;
                flex-direction: column;
                font-weight: 600;
                color: red;
            }
        }

        &__buttons{
            flex-direction: column;
            align-items: center;

            &_singUp{
                height: 80px;
                width: 350px;
                background-color: #C99F62;
                border-radius: 0px 20px 0px 20px;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: #241512;
                font-weight: 600;
                font-size: 22px;
                line-height: 31px;
                cursor: pointer;
                transition: .5s all;
            }

            &_singUp:hover{
                background-color: #D4B382;
            }

            &_singUp:active{
                background-color: #A17536;
            }

            &_singUp:disabled{
                cursor: default;
                background-color: #A6A6A6;

            }
        }
    }
}

.loadinWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 500px) {
    .signUp{
        height: calc(100vh - 80px);
        // min-height: 800px;

        &__wrapper{
            height: calc(100vh - 80px);
            padding: 32px 0px 40px 0px;
            // min-height: 800px;

            &__header{
                width: 100%;
    
                &_img{
                    width: 238px;
                    height: 72px;
                }
    
                &__text{
                    margin-top: 32px;
    
                    &_title{
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 31px;
                    }
    
                    &_link{
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 23px;
                        color: #9D9C9C;
                    }
    
                    &_link:hover{
                        color: #9D9C9C;
                    }
                }
            }

            &__main{
                width: 100%;
    
                &_input, &_inputName{
                    height: 42px;
                    padding: 11px 24px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                }
    
                &__wrapperInput{
                    flex-direction: column;
                    margin-top: 24px;
                }
    
                &_inputName{
                    width: 100%;
                    margin-top: 24px;
                }

                &_inputName:first-child{
                    margin-top: 0px;
                }
    
                &__errorState{
                    margin-top: 16px;
                    height: 20px;
                    flex-direction: column;
                    font-weight: 600;
                    color: red;
                }
            }

            &__buttons{
               width: 100%;
    
                &_singUp{
                    height: 55px;
                    width: 100%;
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 23px;
                }
    
                &_singUp:hover{
                    background-color: #C99F62;
                }
    
                &_singUp:active{
                    background-color: #C99F62;
                }
    
                &_singUp:disabled{
                    background-color: #A6A6A6;
                }
            }
        }
    }
}