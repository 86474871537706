.oneDay{
    *{
        display: flex;
    }
    flex-direction: column;
    width: 30%;

    &__wrapper{
        flex-direction: column;
        color: #D1CFCF;
        margin-left: 8px;
        margin-right: 8px;

        &__date{
            justify-content: center;
            flex-direction: column;

            &_day{
                justify-content: center;
                font-size: 22px;
            }

            &_dayWeek{
                text-align: center;
                justify-content: center;
                margin-top: 8px;
                font-size: 18px;
            }
        }

        &__lessons{
            width: 100%;
            margin-top: 24px;
            flex-direction: column;

            &_noLessons{
                // justify-content: center;
                font-size: 20px;
            }
        }
    }
}