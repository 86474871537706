.g-wrapperScreens{
    width: 100%;
    flex-direction: column;
}

.App{
    width: 100%;
    flex-direction: column;
}

