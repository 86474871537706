.user{
    padding-bottom: 120px;
    margin-top: 120px;
    *{
        display: flex;
    }
    &__wrapper{
        flex-direction: column;
        // width: 520px;
        display: flex;
        position: relative;

        &_chooseStudent{
            width: 520px;
            height: 55px;
            display: flex;
            border: 1px solid #C99F62;
            border-radius: 20px;
            align-items: center;
            padding: 0px 16px;
            cursor: pointer;
            background-color:transparent;
            color: white;
        }

        &_usersList{
            width: 520px;
            background-color: #fff;
            max-height: 250px;
            flex-direction: row;
            flex-wrap: wrap;
            padding-bottom: 4px;
            display: flex;
            position: absolute;
            top: 74px;
            z-index: 1;
            border-radius: 10px;
        }

        &_usersLessons{
            margin-top: 24px;
            flex-direction: column;
        }

        &__emptyUsersLessons{
            margin-top: 120px;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &_text{
                font-size: 32px;
            }

            &_btn{
                width: 353px;
                height: 60px;
                background: #C99F62;
                border-radius: 0px 20px;
                color: #241512;
                font-weight: 400;
                font-size: 22px;
                cursor:pointer;
                align-items: center;
                justify-content: center;
                margin-top: 44px;
            }

            &_btn:hover{
                background-color: #D4B382;
            }
        }
    }
}

.loadinWrapper{
    width: 100%;
    // height: 100vh;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}