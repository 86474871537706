body {
    margin: 0px;
    padding-top: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    color: #C99F62;
    font-family: 'Montserrat', sans-serif;
    background-color: #241512;
}

.g-container{
    margin: 0 auto;
    width: 1200px;
    padding: 0px 30px;
    flex-direction: column;
}

* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
}

// .react-datepicker__month-container{
//     *{
//         display: block;
//     }
//     .react-datepicker__week{
//         display: flex;
//     }
// }

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

button{
    padding: 0;
    border: none;
    background: none;
}

p{
    margin: 0;
}

.react-time-picker__button{
    display: none;
}

div.react-time-picker__wrapper{
    border: none;
    margin-top: 16px;
    caret-color: white;
    color: white;
}
input.react-time-picker__inputGroup__input {
    color:aliceblue
}

input.react-time-picker__inputGroup__hour{
    color:aliceblue
}


@media only screen and (max-width: 500px) {
    .g-container{
        width: 100%;
    }
}