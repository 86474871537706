.schedule{
    *{
        display: flex;
    }
    &__wrapper{
        margin-top: 120px;
        width: 100%;

        &_list{
            flex-direction: row;
            justify-content: center;
            width: 100%;
        }

        &_back,&_forward{
            width: 24px;
            height: 24px;
            cursor: pointer;
            margin-top: 24px;
        }

        &_back{
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        &_hide{
            display: none;
        }
    }
}