.loadinWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInfo{

    *{
        display: flex;
    }

    &__wrapper{

        justify-content: space-between;
        flex-direction: column;
        // background-color: #fff;
        height: calc(100vh - 108px);

        &__scheduleWrap{
            flex-direction: column;
        }

        &__title{
            justify-content: center;

            &_header{
                font-size: 36px;
                font-weight: 400;
                text-align: center;
                align-items: center;
                justify-content: center;
                margin: 0px;
                margin-top: 80px;
                position: relative;
                text-transform: uppercase;
            }

            &_header::before{
                content: '';
                background-color: #C99F62;
                width: 222px;
                height: 1px;
                position: absolute;
                top: 0px;
                left: -180px;
            }

            &_header::after{
                content: '';
                background-color: #C99F62;
                width: 222px;
                height: 1px;
                position: absolute;
                bottom: 0px;
                right: -180px;
            }
        }

        &__empty{
            margin-top: 144px;
            justify-content: center;
            font-size: 24px;
            color: #E9E2D0;
        }

        &__schedule{
            width: 100%;
            margin-top: 64px;
            flex-direction: column;
            position: relative;

            &__title{
                width: 100%;
                justify-content: space-between;
                height: 54px;
                font-size: 20px;
                font-weight: 700;

                &_lessons{
                    align-items: center;
                    justify-content: center;
                    width: 25%;
                }

                &_date{
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                }

                &_time{
                    align-items: center;
                    justify-content: center;
                    width: 25%;
                }
            }

            &__incription{
                margin-top: 24px;
                font-size: 16px;

                &_paid{
                    width: 24px;
                    height: 24px;
                    background: #275031;
                }

                &_unpaid{
                    width: 24px;
                    height: 24px;
                    background: #571816;
                    margin-left: 40px;
                }

                &_text{
                    margin-left: 8px;
                }
            }
        }

        &__schedule::after,&__schedule::before{
            content: '';
            position: absolute;
            height: 100%;
            width: 1px;
            background: rgba(209, 207, 207, 0.1);
        }

        &__schedule::after{
            right: 25%;
        }

        &__schedule::before{
            left: 25%;
        }

        &__footer{
            align-items: center;
            flex-direction: column;
            padding-bottom: 40px;

            &_text{
                color: #E9E2D0;
                font-size: 18px;
                margin-top: 64px;
            }

            &__wrapper{
                margin-top: 24px;

                &_img{
                    margin-left: 12px;
                    margin-right: 12px;
                }

                &_img:hover{
                    cursor: pointer;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .userInfo{
        &__wrapper{
            height: calc(100vh - 128px);

            &__title{
                justify-content: center;
    
                &_header{
                    font-size: 24px;
                    margin-top: 40px;
                }
    
                &_header::before{
                    content: none;
                }
    
                &_header::after{
                    content: none;
                }
            }

            &__empty{
                margin-top: 0px;
                font-size: 24px;
            }
    
            &__schedule{
                margin-top: 54px;
    
                &__title{
                    height: 40px;
                    font-size: 16px;
                    font-weight: 400;

                }
    
                &__incription{
                    margin-top: 32px;
                    align-items: center;
                }
            }

            &__schedule::after,&__schedule::before{
                content: none;
            }

            &__footer{
                padding-bottom: 40px;
    
                &__wrapper{
                    margin-top: 24px;
    
                    &_img{
                        margin-left: 24px;
                        margin-right: 24px;
                    }
    
                    &_img:hover{
                        cursor: pointer;
                    }
                }
            }
        }    
    }
}
