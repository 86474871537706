.welcome{
    width: 100vw;
    height: 100vh;
    background-color: #241512;
    display: flex;
    min-height: 600px;

    &__wrapper{
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        padding: 24px 0px 80px;

        &_img{
            width: 475px;
            height: 140px;
        }

        &__buttons{
            flex-direction: column;

            &__singUp, &__singIn{
                height: 80px;
                width: 350px;
                background-color: #C99F62;
                border-radius: 0px 20px 0px 20px;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: #241512;
                font-weight: 600;
                font-size: 22px;
                line-height: 31px;
                cursor: pointer;
                transition: .5s all;
            }

            &__singIn{
                margin-top: 44px;
            }

            &__singUp:hover, &__singIn:hover{
                background-color: #D4B382;
            }

            &__singUp:active, &__singIn:active{
                background-color: #A17536;
            }

        }

        &__social{
                
            &_item{
                margin: 0px 12px;
                cursor: pointer;
                width: 48px;
                height: 48px;
            }
        }
    }
    * {
        display: flex;
    }
}


@media only screen and (max-width: 500px) {
    .welcome{
        height: calc(100vh - 80px);

        &__wrapper{

            padding: 32px 0px 40px 0px;

            &_img{
                width: 238px;
                height: 72px;
            }
    
            &__buttons{
                width: 100%;
    
                &__singUp, &__singIn{
                    height: 55px;
                    width: 100%;
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 23px;
                }
    
                &__singIn{
                    margin-top: 24px;
                }

                &__singUp:hover, &__singIn:hover{
                    background-color: #C99F62;
                }
    
                &__singUp:active, &__singIn:active{
                    background-color: #C99F62;
                }
    
            }
    
            &__social{

                display: none;

            }
        }
    }
}