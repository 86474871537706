.lesson{

    *{
        display: flex;
    }

    width: 100%;
    height: 60px;
    flex-direction: row;
    margin-top: 24px;
    font-size: 18px;
    font-weight: 500;
    color: #E9E2D0;

    &__type{
        width: 25%;
        align-items: center;
        justify-content: center;
    }

    &__date{
        width: 50%;
        align-items: center;
        justify-content: center;
    }

    &__time{
        width: 25%;
        align-items: center;
        justify-content: center;
    }
}

.lessonGreen{
    background: rgba(47, 255, 143, 0.25);
}

.lessonRed{
    background: rgba(239, 33, 33, 0.25);
}

@media only screen and (max-width: 500px) {
    .lesson{
        margin-top: 16px;
        font-size: 14px;
        font-weight: 400;
    }
}