.student{
    margin-top: 120px;


    &__wrapper{
        flex-direction: column;
        width: 520px;
        display: flex;
        position: relative;

        &_chooseStudent{
            height: 55px;
            display: flex;
            border: 1px solid #C99F62;
            border-radius: 20px;
            align-items: center;
            padding: 0px 16px;
            cursor: pointer;
            background-color:transparent;
            color: white;
        }

        &_usersList{
            width: 520px;
            background-color: #fff;
            max-height: 250px;
            flex-direction: row;
            flex-wrap: wrap;
            padding-bottom: 4px;
            display: flex;
            position: absolute;
            top: 74px;
            z-index: 1;
            border-radius: 10px;
        }

        &_date{
            height: 55px;
            border: 1px solid #C99F62;
            width: 100%;
            border-radius: 20px;
            align-items: center;
            padding: 0px 16px;
            cursor: pointer;
            background-color:transparent;
            color: white;
            margin-top: 24px;
        }

        &_time{
            height: 55px;
            border: 1px solid #C99F62;
            width: 100%;
            border-radius: 20px;
            align-items: center;
            padding: 0px 16px;
            cursor: pointer;
            background-color:transparent;
            color: white;
            margin-top: 24px;
        }

        &__checkBoxes{
            flex-direction: row;
            display: flex;
            margin-top: 12px;
            justify-content: space-between;
        }

        &_button{
            height: 80px;
            width: 350px;
            background-color: #C99F62;
            border-radius: 0px 20px 0px 20px;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: #241512;
            font-weight: 600;
            font-size: 22px;
            line-height: 31px;
            cursor: pointer;
            transition: .5s all;
            margin-top: 40px;
        }

        &_button:hover{
            background-color: #D4B382;
        }

        &_button:active{
            background-color: #A17536;
        }

        &_button:disabled{
            cursor: default;
            background-color: #A6A6A6;

        }
    }
}

.loadinWrapper{
    width: 100%;
    // height: 100vh;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}