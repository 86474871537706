.list{
    margin: 4px 12px 0px;
    padding-left: 16px;
    cursor: pointer;
    height: 40px;
    align-items: center;
    border-radius: 16px;
    background-color: grey;
    color: white;
    width: 44%;
    display: flex;
    // width: 520px;

    &_name{
        text-transform: capitalize;
    }

    &_surname{
        text-transform: capitalize;
        margin-left: 8px;
    }

    &_email{
        margin-left: 8px;
    }
}

.list:hover{
    background-color: black;
}